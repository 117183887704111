<template>
	<errorContainer :error="erreur" :warning="warning">
		<custom-loading-planning :loading="preparePlanning" :label="FormMSG(103, 'Preparation of the planning and the list of tasks')" />
		<b-col sm="12">
			<h1 :class="`${!$screen.sm ? 'main-page-title' : ''}`">{{ !toMe ? FormMSG(159, 'Plannings') : FormMSG(16, 'My Plannings') }}</h1>
		</b-col>
		<b-row class="mb-4">
			<b-col sm="12" md="12" lg="4" xl="4" v-if="!toMe">
				<fieldset class="scheduler-border my-0 py-0 pb-3">
					<legend class="scheduler-border">{{ FormMSG(14, 'Menus') }}</legend>
					<b-row>
						<b-col sm="12" md="12" lg="12" xl="6" :class="`${$screen.lg || $screen.md || $screen.width < 768 ? 'mb-2' : ''}`">
							<display-settings-plannings
								@settings-plannings:change="handleSettingsPlanningsChange"
								@settings-plannings:input-height-cell="handleSettingsPlanningsInputHeightCell"
								@settings-plannings:input-time-step="handleSettingsPlanningsInputTimeStep"
								@settings-plannings:input-time-to="handleSettingsPlanningsInputTimeTo"
								@settings-plannings:input-time-from="handleSettingsPlanningsInputTimeFrom"
								@settings-plannings:close-modal="handleSettingsPlanningsCloseModal"
								class="w-100"
							/>
						</b-col>
						<b-col sm="12" md="12" lg="12" xl="6" :class="`${$screen.lg || $screen.md || $screen.width < 768 ? 'mb-2' : ''}`">
							<router-link to="/gantt" tag="button" class="btn btn-success w-100">
								<i class="fa fa-tasks" aria-hidden="true" />
								&nbsp;{{ FormMSG(125, 'Gantt') }}
							</router-link>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="12" xl="6">
							<router-link to="/perAssignee" tag="button" class="btn btn-success w-100">
								<i class="fa fa-user-o" aria-hidden="true" />
								&nbsp;{{ FormMSG(169, 'Per assignee') }}
							</router-link>
						</b-col>
						<b-col sm="12" md="12" lg="12" xl="6">
							<router-link to="/assignationMultiple" tag="button" class="btn btn-success w-100" :disabled="!canManagePlanning">
								<i class="fa fa-users" aria-hidden="true" />
								&nbsp;{{ FormMSG(45, 'Multiple assignments') }}
							</router-link>
						</b-col>
					</b-row>
				</fieldset>
			</b-col>
			<b-col sm="12" md="12" lg="8" xl="8">
				<fieldset class="scheduler-border my-0 py-0">
					<legend class="scheduler-border">{{ FormMSG(15, 'Advanced search') }}</legend>
					<filter-planning
						ref="filter-planning"
						:field-filter="'event'"
						:to-me="toMe"
						:only-parent="chckOnlyParent"
						init-active-view="week"
						@filters:selected="handleFiltersSelected"
						@new-active-view="handleNewActiveView"
						@today-event="$refs.taskcal.switchView('day', new Date())"
						@specific-date:selected="handleSpecificDateSelected"
					/>
				</fieldset>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" class="pb-2">
				<div class="pull-right">
					<b-button v-if="!toMe" variant="primary" @click="handleToggleTasks">
						<span v-if="!toggleTasks">
							<i class="fa fa-angle-double-right" style="margin-left: 2px" />
							&nbsp;{{ FormMSG(43, 'Hide') }}
						</span>
						<span v-else>
							<i class="fa fa-angle-double-left" style="margin-left: 2px" />
							&nbsp;{{ FormMSG(44, 'Show') }}
						</span>
					</b-button>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col :cols="!toggleTasks ? 9 : 12" style="transition: max-width 0.5s">
				<vue-cal
					ref="taskcal"
					style="height: auto"
					:selected-date="selectedDate"
					:active-view.sync="activeView"
					:disable-views="disableViews"
					:events="events"
					:locale="lang"
					:on-event-click="openDetailsForPlanning"
					:time-step="configPlannings.timeStep"
					:time-from="configPlannings.timeFrom * 60"
					:time-to="configPlannings.timeTo * 60"
					:time-cell-height="configPlannings.heightCell"
					:hide-weekends="!configPlannings.showWeeks"
					:resize-x="true"
					events-on-month-view="short"
					events-count-on-year-view
					hide-view-selector
					:drag-to-create-event="false"
					:cell-click-hold="false"
					:editable-events="checkEditableEvents"
					@event-drop="onEventDrop"
					@event-delete="onEventDelete"
					@event-duration-change="handleEventDurationChange"
					@event-change="handleEventChange"
					@view-change="handleViewChange"
				>
					<template #event="{ event, view }">
						<div v-if="!['years', 'year', 'month'].includes(view)" class="pt-2">
							<div class="text-left ml-3" style="position: relative">
								<view-list-img-circle :list-users="event.taskResourceUsers" :list-teams="event.taskResourceTeams" />
								<i
									class="fa fa-flag"
									style="position: absolute; right: 10px; top: 2px"
									:style="{ color: getColorOrStrPriority(event.priority) }"
								/>
							</div>

							<div
								class="vuecal__event-title vuecal__event-title--edit mt-3"
								style="font-weight: bolder"
								:style="{ color: `${event.textColor} !important` }"
								contenteditable
								@blur="event.title = $event.target.innerHTML"
								v-html="event.title"
							/>
							<task-type-display :task-type="event.taskType" :text-color="event.textColor" />
							<div v-if="event.clientId !== 0" class="text-center mt-1">
								<div v-if="event.taskParentRootName !== '0'" class="mt-3 mb-3">
									<span style="font-weight: bolder; font-style: italic" :style="{ color: `${event.textColor} !important` }">
										{{ FormMSG(170, 'Main task') }} :
									</span>
									<span :style="{ color: `${event.textColor} !important` }">
										{{ event.taskParentRootName }}
									</span>
								</div>
								<div>
									<span style="font-weight: bolder; font-style: italic" :style="{ color: `${event.textColor} !important` }">
										{{ FormMSG(165, 'Client') }} :
									</span>
									<span :style="{ color: `${event.textColor} !important` }">
										{{ event.client.name }}
									</span>
								</div>
								<div>
									<span style="font-weight: bolder; font-style: italic" :style="{ color: `${event.textColor} !important` }">
										{{ FormMSG(166, 'Address') }} :
									</span>
									<span v-if="event.locationId === 0" :style="{ color: `${event.textColor} !important` }">
										{{ getAddressFormater(event.client.address) }}
									</span>
									<span v-else :style="{ color: `${event.textColor} !important` }">
										{{ getAddressFormater(event.location.setAddress) }}
									</span>
								</div>
								<div class="text-center">
									<b-button
										variant="primary"
										style="background: none; border: none; font-size: 16px; color: #43c3d0"
										:style="{ color: `${event.textColor} !important` }"
										@click.stop="handleClickLocalisation(event)"
									>
										<i class="icon-location-pin" />
									</b-button>
								</div>
							</div>
						</div>
					</template>
					<template #time-cell="{ hours, minutes }">
						<div :class="{ 'vuecal__time-cell-line': true, hours: !minutes }">
							<strong v-if="!minutes" style="font-size: 15px">{{ hours }}</strong>
							<span v-else style="font-size: 11px">
								{{ minutes }}
							</span>
						</div>
					</template>
					<template v-slot:events-count="{ events, view }">
						<span v-if="customEventsCount(events)">
							{{ customEventsCount(events) }}
						</span>
					</template>
				</vue-cal>

				<modal-planning
					:open="showDialog"
					:selected-event="selectedEvent"
					:raw-users="rawUsers"
					:raw-teams="rawTeams"
					@modal:close="closeDetailsForPlanning"
					@change:info="handleChangeInfoEvent"
					@change:info-task="handleChangeInfoTask"
					@change:push-task-to-event="handleChangePushTaskToEvent"
					@refresh-plannings="handleRefreshPlannings"
				/>
			</b-col>
			<!-- TASK LIST -->
			<b-col :cols="!toggleTasks ? 3 : ''" :style="{ display: !toggleTasks ? 'block' : 'none' }" style="z-index: 0">
				<div class="sidenav-sticky sticky-top pb-4">
					<h3>
						<span> {{ FormMSG(61, 'Tasks') }}</span>
					</h3>
					<div v-if="tasks.length === 0" class="mt-5">
						{{ FormMSG(46, 'No tasks to plan') }}
					</div>
					<b-list-group id="task-planning" style="overflow-y: scroll" :style="{ height: heightRightTask }">
						<b-list-group-item
							v-for="(item, index) in tasks"
							:key="index"
							href="#"
							class="flex-column align-items-start"
							:draggable="true"
							@dragstart="onEventDragStart($event, item)"
							@click="openDetails(item)"
						>
							<div class="d-flex w-100 justify-content-between">
								<p style="font-weight: bolder">{{ item.title }}</p>
								<small>
									<span
										style="
											display: inline-block;
											border-radius: 5px;
											text-transform: capitalize;
											padding: 3px;
											color: white;
											font-weight: bolder;
										"
										:style="{
											backgroundColor: `#${item.backgroundColor}`,
											color: `#${item.textColor}`
										}"
										class="mr-2"
									>
										{{ getClassColorStatus(item.status, 'text') }}
									</span>
									<i
										v-b-popover.hover.bottom="getColorOrStrPriority(item.priority, 'str')"
										class="fa fa-flag"
										style="font-size: 15px"
										:style="{ color: getColorOrStrPriority(item.priority) }"
									/>
								</small>
							</div>
							<div v-if="item.taskParentRootName !== '0'" style="margin-top: -10px">
								<span style="font-weight: bolder; font-style: italic">{{ FormMSG(170, 'Main task') }} : </span
								><span style="font-style: italic">{{ item.taskParentRootName }}</span>
							</div>
							<!--b-row>
                <b-col>
                  <p>
                    <small
                      style="color: gray; font-weight: bolder; font-style: italic"
                    >{{ item.taskType.name }}</small>
                  </p>
                </b-col>
              </b-row-->
							<small class="float-left" style="color: gray; font-weight: bolder">
								{{ item.durationString }}
							</small>
							<small
								v-b-popover.hover.bottom="FormMSG(13, 'View information for this task')"
								class="float-right"
								style="color: gray; font-weight: bolder"
							>
								<i class="fa fa-eye" aria-hidden="true" style="font-size: 15px" />
							</small>
						</b-list-group-item>
						<modal-task-planning
							:open="showDialogTasks"
							:selected-event="selectedTask"
							@modal:close="closeDetails"
							@task:save="handleTaskSave"
							@task:save-planned="handleTaskSavePlanned"
						/>
					</b-list-group>
				</div>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/fr.js';
import 'vue-cal/dist/i18n/it.js';
import 'vue-cal/dist/i18n/de.js';
import 'vue-cal/dist/i18n/nl.js';
import 'vue-cal/dist/i18n/pt-br.js';
import 'vue-cal/dist/i18n/sk.js';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/drag-and-drop.js';
import moment from 'moment';
import { store } from '@/store';
import ModalPlanning from './ModalPlanning';
import ViewListImgCircle from './ViewListImgCircle';
import { getTasks, getTaskResourceUsers, getTaskResourceTeams, updatedTask, setDesiredStartTask } from '@/cruds/task.crud';
import languageMessages from '@/mixins/languageMessages';
import { getUsers } from '@/cruds/users.crud';
import { getTeams } from '@/cruds/team.crud';
import dateMixin from '@/mixins/date.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import FilterPlanning from '@/components/Plannings/Filter/FilterPlanning';
import ModalTaskPlanning from './ModalTaskPlanning';
import CustomLoadingPlanning from './Filter/CustomLoadingPlanning';
import DisplaySettingsPlannings from './DisplaySettingsPlannings';
import TaskTypeDisplay from './TaskTypeDisplay';
import { addressFormatter } from '@/shared/fomaters';

const TaskColorPriority = Object.freeze({
	URGENT: '#F50000',
	HIGH: '#FFCC00',
	NORMAL: '#6FDDFF',
	LOW: '#D8D8D8'
});

export default {
	name: 'PlanningsComponent',

	components: {
		VueCal,
		ModalPlanning,
		ViewListImgCircle,
		FilterPlanning,
		ModalTaskPlanning,
		CustomLoadingPlanning,
		DisplaySettingsPlannings,
		TaskTypeDisplay
	},

	mixins: [languageMessages, dateMixin, GlobalMixin],

	props: {
		isMyPlannings: { type: Boolean, required: true }
	},

	data() {
		return {
			erreur: {},
			warning: '',

			selectedDate: new Date(),
			events: [],
			originalEvents: [],
			tasks: [],
			showDialog: false,
			showDialogTasks: false,
			openModalTaskPlanning: false,
			selectedEvent: {},
			selectedTask: {},
			toggleTasks: false,
			activeView: 'week',

			rawUsers: [],
			rawTeams: [],

			filter: {
				startDate: new Date(moment().startOf('week').add(1, 'days')).toISOString(),
				endDate: new Date(moment().endOf('week').add(1, 'days')).toISOString(),
				isMine: false,
				filterActive: '()'
			},

			toMe: false,

			isTaskModal: false,
			preparePlanning: false,

			configPlannings: {
				timeFrom: 0,
				timeTo: 20,
				timeStep: 15,
				showWeeks: false,
				heightCell: 10
			},
			oldConfigPlannings: {
				timeFrom: 0,
				timeTo: 20,
				timeStep: 15,
				showWeeks: false,
				heightCell: 10
			},
			chckOnlyParent: false,
			cookiesPlannings: {
				startDate: new Date(moment().startOf('week').add(1, 'days')).toISOString(),
				endDate: new Date(moment().endOf('week').add(1, 'days')).toISOString(),
				isMine: false,
				filterActive: '()',
				toggleTasks: false,
				activeView: 'week',
				selectedDate: new Date(),
				chckOnlyParent: false,

				cookieName: ''
			},
			cookieName: '',
			initViewChange: true
		};
	},

	computed: {
		heightRightTask() {
			const diffHours = this.configPlannings.timeTo - this.configPlannings.timeFrom + 1;
			const timeStep = this.configPlannings.timeStep === 0 ? 30 : this.configPlannings.timeStep;
			const nbTenMinutes = 60 / timeStep;
			const resHoursMinutes = diffHours * (this.configPlannings.timeStep * nbTenMinutes) + 70;
			return resHoursMinutes + 'px';
		},
		disableViews() {
			if (this.$screen.width > 990) {
				return [];
			} else {
				return ['week'];
			}
		},
		checkEditableEvents() {
			if (this.isMyPlannings) {
				return {
					title: false,
					drag: false,
					resize: false,
					delete: false,
					create: false
				};
			} else {
				if (!store.isAdmin() && !store.isProd()) {
					return {
						title: false,
						drag: false,
						resize: false,
						delete: false,
						create: false
					};
				} else if (store.isAdmin() && !store.isProd()) {
					return {
						title: false,
						drag: true,
						resize: true,
						delete: false,
						create: false
					};
				} else if (!store.isAdmin() && store.isProd()) {
					return {
						title: false,
						drag: true,
						resize: true,
						delete: false,
						create: false
					};
				} else if (store.isAdmin() && store.isProd()) {
					return {
						title: false,
						drag: true,
						resize: true,
						delete: false,
						create: false
					};
				}
			}
		},
		lang() {
			const langStore = store.state.myProfile.appLanguage;
			switch (langStore) {
				case 0:
					return 'en';
				case 1:
					return 'fr';
				case 2:
					return 'de';
				case 3:
					return 'it';
				case 4:
					return 'nl';
				case 5:
					return 'en';
				case 6:
					return 'pt-br';
				case 7:
					return 'sk';
			}
		},
		activeViewText() {
			if (this.activeView === 'years') {
				return this.FormMSG(4, 'Years');
			}
			if (this.activeView === 'year') {
				return this.FormMSG(5, 'Year');
			}
			if (this.activeView === 'month') {
				return this.FormMSG(6, 'Month');
			}
			if (this.activeView === 'week') {
				return this.FormMSG(7, 'Week');
			}
			if (this.activeView === 'day') {
				return this.FormMSG(8, 'Day');
			}
		},
		menuPriority() {
			return this.FormMenu(1123);
		},
		statuses() {
			const statuses = this.FormMenu(1122);
			return statuses.sort((a, b) => a.value - b.value);
		},
		canManagePlanning() {
			return store.canAssign();
		}
	},

	async created() {
		await this.getCookiesPlannings();
	},

	async mounted() {
		// await this.getColorStatuses()
		// await this.getCookiesPlannings()
		await this.getCookiesConfigPlannings();
		this.oldConfigPlannings.heightCell = this.configPlannings.heightCell;
		this.oldConfigPlannings.timeTo = this.configPlannings.timeTo;
		this.oldConfigPlannings.timeFrom = this.configPlannings.timeFrom;
		this.oldConfigPlannings.timeStep = this.configPlannings.timeStep;

		this.preparePlanning = true;
		if (this.$screen.width <= 990) {
			this.activeView = 'month';
		}

		if (this.$route.name === 'myPlannings') {
			this.toMe = true;
			this.toggleTasks = true;
		}

		await this.getRawUsers();
		await this.getRawTeams();

		if (!this.isMyPlannings) {
			await this.getTaskList();
		}
		/*this.filter.startDate = new Date(
      moment().startOf('week').add(1, 'days')
    ).toISOString()
    this.filter.endDate = new Date(
      moment().endOf('week').add(1, 'days')
    ).toISOString()*/
		//await this.setCookiesPlannings()
		//await this.getCookiesPlannings()

		if (this.isMyPlannings) {
			await this.getTasksByDate();
		}
		this.preparePlanning = false;

		// Pour supprimer le breadcrumb
		this.$nextTick(() => {
			const breadcrumb = document.querySelector('ol.breadcrumb');
			breadcrumb.style.display = 'none';
			breadcrumb.style.marginBottom = '20px';
		});
	},

	destroyed() {
		this.$nextTick(() => {
			const breadcrumb = document.querySelector('ol.breadcrumb');
			breadcrumb.style.display = 'flex';
			breadcrumb.style.marginBottom = '0';
		});
	},

	methods: {
		getCookiesPlannings() {
			switch (this.$route.name) {
				case 'plannings':
					this.cookieName = 'ck_plannings_component_for_plannings';
					break;
				case 'myPlannings':
					this.cookieName = 'ck_plannings_component_for_myPlannings';
					break;
				case 'AdministrationPlannings':
					this.cookieName = 'ck_plannings_component_for_admin_myPlannings';
					break;
			}
			if (this.$cookies.isKey(this.cookieName)) {
				this.cookiesPlannings = this.$cookies.get(this.cookieName);
				this.filter.startDate = new Date(this.cookiesPlannings.startDate).toISOString();
				this.filter.endDate = new Date(this.cookiesPlannings.endDate).toISOString();
				this.filter.isMine = this.cookiesPlannings.isMine;
				this.filter.filterActive = this.cookiesPlannings.filterActive;
				this.activeView = this.cookiesPlannings.activeView;
				this.toggleTasks = this.cookiesPlannings.toggleTasks;
				this.selectedDate = new Date(this.cookiesPlannings.selectedDate);
				this.chckOnlyParent = this.cookiesPlannings.chckOnlyParent;
			} else {
				this.$cookies.set(this.cookieName, this.cookiesPlannings, '2d');
			}
		},
		setCookiesPlannings(here) {
			this.cookiesPlannings.startDate = this.filter.startDate;
			this.cookiesPlannings.endDate = this.filter.endDate;
			this.cookiesPlannings.isMine = this.filter.isMine;
			this.cookiesPlannings.filterActive = this.filter.filterActive;
			this.cookiesPlannings.activeView = this.activeView;
			this.cookiesPlannings.toggleTasks = this.toggleTasks;
			this.cookiesPlannings.selectedDate = this.selectedDate;
			this.cookiesPlannings.chckOnlyParent = this.chckOnlyParent;
			this.$cookies.set(this.cookieName, this.cookiesPlannings, '2d');
		},
		getAddressFormater(address) {
			return addressFormatter(address);
		},
		async handleClickLocalisation(event) {
			if (event.locationId === 0) {
				await this.displayMap(event.client.address);
			} else {
				await this.displayMap(event.location.setAddress);
			}
		},
		handleSettingsPlanningsCloseModal() {
			this.configPlannings.heightCell = this.oldConfigPlannings.heightCell;
			this.configPlannings.timeTo = this.oldConfigPlannings.timeTo;
			this.configPlannings.timeFrom = this.oldConfigPlannings.timeFrom;
			this.configPlannings.timeStep = this.oldConfigPlannings.timeStep;
		},
		handleSettingsPlanningsInputTimeFrom(payload) {
			this.configPlannings.timeFrom = parseInt(payload);
		},
		handleSettingsPlanningsInputTimeTo(payload) {
			this.configPlannings.timeTo = parseInt(payload);
		},
		handleSettingsPlanningsInputTimeStep(payload) {
			this.configPlannings.timeStep = parseInt(payload);
		},
		handleSettingsPlanningsInputHeightCell(payload) {
			this.configPlannings.heightCell = parseInt(payload);
		},
		handleSettingsPlanningsChange(payload) {
			this.configPlannings.timeFrom = payload.timeFrom;
			this.configPlannings.timeTo = payload.timeTo;
			this.configPlannings.timeStep = payload.timeStep;
			this.configPlannings.showWeeks = payload.showWeeks === 'true';
			this.configPlannings.heightCell = payload.heightCell;

			this.oldConfigPlannings.heightCell = this.configPlannings.heightCell;
			this.oldConfigPlannings.timeTo = this.configPlannings.timeTo;
			this.oldConfigPlannings.timeFrom = this.configPlannings.timeFrom;
			this.oldConfigPlannings.timeStep = this.configPlannings.timeStep;
		},
		getCookiesConfigPlannings() {
			if (this.$cookies.isKey('configPlannings')) {
				const cookies = this.$cookies.get('configPlannings');

				this.configPlannings.timeFrom = cookies.timeFrom;
				this.configPlannings.timeTo = cookies.timeTo;
				this.configPlannings.timeStep = cookies.timeStep === 0 ? 30 : cookies.timeStep;
				this.configPlannings.showWeeks = cookies.showWeeks === 'true';
				this.configPlannings.heightCell = cookies.heightCell;
			} else {
				this.$cookies.set('configPlannings', {
					timeFrom: 0,
					timeTo: 20,
					timeStep: 15,
					showWeeks: false,
					heightCell: 10
				});
			}
		},
		handleSpecificDateSelected(payload) {
			this.filter.startDate = payload.startDate;
			this.filter.endDate = payload.endDate;

			this.selectedDate = new Date(payload.startDate);
			this.getTasksByDate();
			this.$refs.taskcal.switchView('day');
			this.setCookiesPlannings('handleSpecificDateSelected');
		},
		handleNewActiveView(payload) {
			this.activeView = payload;
			//this.setCookiesPlannings('handleNewActiveView')
		},
		async handleRefreshPlannings(payload) {
			this.preparePlanning = true;
			await this.getTaskList();
			await this.getTasksByDate();
		},
		handleTaskSavePlanned(payload) {
			this.tasks = this.tasks.filter((t) => t.id !== payload.id && t.taskPlanningId !== payload.taskPlanningId);
			this.getTasksByDate();
		},
		handleTaskSave(payload) {
			this.tasks.map((t) => {
				if (t.id === payload.id && t.taskPlanningId === payload.taskPlanningId) {
					t.priority = payload.priority;
					t.dayDuration = payload.dayDuration;
					t.duration = payload.duration;
					t.desiredStart = payload.desiredStart;
					t.durationString = payload.durationString;
				}
			});
		},
		handleChangePushTaskToEvent(payload) {
			const filters = this.tasks.filters((t) => t.id === payload.id);
			for (let i = 0; i < filters.length; i++) {
				const f = filters[i];
				const findIndex = this.tasks.findIndex((t) => t.id === f.id && t.taskPlanningId === f.taskPlanningId);
				this.tasks.splice(findIndex, 1);
				const colorAndTextStatus = this.getColorAndTextStatus(f.status);
				f.class = this.getClassColorStatus(f.status);
				f.textColor = colorAndTextStatus.textColor;
				f.title = `<span style="color: ${colorAndTextStatus.textColor}">${f.title}</span>`;
				this.events.push(f);
			}
		},
		async handleChangeInfoTask(payload) {
			for (let i = 0; i < this.tasks.length; i++) {
				const t = this.tasks[i];
				const colorAndTextStatus = await this.getColorAndTextStatus(t.status);
				if (t.id === payload.id) {
					t.status = payload.status;
					t.classStatus = this.getClassColorStatus(payload.status);
					t.textColor = colorAndTextStatus.textColor;
					t.title = `<span style="color: ${colorAndTextStatus.textColor}">${t.title}</span>`;
				}
				if (t.id === payload.id && t.taskPlanningId === payload.taskPlanningId) {
					t.start = new Date(payload.start);
					t.end = new Date(payload.end);
					t.taskResourceUsers = payload.taskResourceUsers;
					t.taskResourceTeams = payload.taskResourceTeams;
					t.textColor = colorAndTextStatus.textColor;
					t.title = `<span style="color: ${colorAndTextStatus.textColor}">${t.title}</span>`;
				}
			}
		},
		handleFiltersSelected({ payload, condition }) {
			this.filter.filterActive = condition;

			this.getEventsWithFilter();
			this.setCookiesPlannings('handleFiltersSelected');
		},
		/**
		 *
		 * @returns {Promise<void>}
		 */
		async getRawTeams() {
			this.rawTeams = await getTeams();
		},
		/**
		 * Get all users for project
		 */
		async getRawUsers() {
			// get all users in project
			const rawUsers = await getUsers({ myProject: true });
			this.rawUsers = rawUsers.map((rawUser) => {
				rawUser.fullName = rawUser.firstName + ' ' + rawUser.name.toUpperCase();

				return rawUser;
			});
		},
		async handleViewChange(payload) {
			this.filter.startDate = new Date(payload.startDate).toISOString();
			this.filter.endDate = new Date(payload.endDate).toISOString();
			this.selectedDate = new Date(payload.startDate);

			await this.getTasksByDate();
			this.setCookiesPlannings('handleViewChange');
			if (!this.initViewChange) {
				this.setCookiesPlannings('handleViewChange');
			}
			this.$refs['filter-planning'].setActiveView(payload.view);
			this.initViewChange = false;
			this.$nextTick(() => {
				const vuecalYearView = document.querySelector('.vuecal--year-view');
			});
		},
		async handleChangeInfoEvent(payload) {
			for (let i = 0; i < this.events.length; i++) {
				const e = this.events[i];
				const colorAndTextStatus = await this.getColorAndTextStatus(payload.status);
				if (e.id === payload.id) {
					e.status = payload.status;
					e.class = this.getClassColorStatus(payload.status);
					e.textColor = colorAndTextStatus.textColor;
					e.title = `<span style="color: ${colorAndTextStatus.textColor}">${e.name}</span>`;
				}
				if (e.id === payload.id && e.taskPlanningId === payload.taskPlanningId) {
					e.start = payload.start.format('YYYY-MM-DD HH:mm');
					e.end = payload.end.format('YYYY-MM-DD HH:mm');
					e.taskResourceUsers = payload.taskResourceUsers;
					e.taskResourceTeams = payload.taskResourceTeams;
					e.textColor = colorAndTextStatus.textColor;
					e.title = `<span style="color: ${colorAndTextStatus.textColor}">${e.name}</span>`;
				}
			}
		},
		async handleEventDurationChange({ event }) {
			const date = new Date(event.end);
			const desiredStart = new Date(event.start);
			const duration = this.durationTimeBetweenTwoDate(event.desiredStart, date.toISOString());
			await updatedTask(event.id, {
				desiredStart,
				duration: duration,
				taskPlanningId: event.taskPlanningId
			});
			this.events.map((e) => {
				if (e.id === event.id && e.taskPlanningId === event.taskPlanningId) {
					e.end = date.format('YYYY-MM-DD HH:mm');
				}
			});
		},
		async handleEventChange({ event }) {
			if (!event.taskPlanningsId) {
				let date = new Date(event.start);
				let dateEnd = new Date(event.end);
				const duration = this.durationTimeBetweenTwoDate(date.toISOString(), dateEnd.toISOString());
				await updatedTask(event.id, {
					desiredStart: date,
					duration: duration,
					taskPlanningId: event.taskPlanningId
				});
				this.events.map((e) => {
					if (e.id === event.id && e.taskPlanningId === event.taskPlanningId) {
						e.desiredStart = date.toISOString();
						e.start = event.start;
						e.end = event.end;
						e.timeDuration = duration;
					}
				});
				const findIndex = this.events.findIndex((e) => e.id === event.id);

				if (findIndex !== -1) {
					this.events[findIndex].desiredStart = date.toISOString();
				}
			}
		},
		openDetails(payload) {
			this.selectedTask = payload;
			this.showDialogTasks = true;
			this.isTaskModal = true;
		},
		openDetailsForPlanning(event, e) {
			this.selectedEvent = event;
			this.showDialog = true;
			this.isTaskModal = false;

			e.stopPropagation();
		},
		closeDetailsForPlanning(payload) {
			this.showDialog = false;
			this.isTaskModal = false;
		},
		closeDetails(payload) {
			this.showDialogTasks = false;
			this.isTaskModal = false;
		},
		onEventDragStart(e, draggable) {
			e.dataTransfer.setData('event', JSON.stringify(draggable));
			e.dataTransfer.setData('cursor-grab-at', e.offsetY);
		},
		async onEventDrop({ event, originalEvent, external }) {
			if (external) {
				const extEventToDeletePos = this.tasks.findIndex(
					(item) => item.id === originalEvent.id && item.taskPlanningId === originalEvent.taskPlanningId
				);
				if (extEventToDeletePos > -1) {
					this.tasks.splice(extEventToDeletePos, 1);
				}

				const beginDate = new Date(event.start);
				const endDate = new Date(event.end);

				await updatedTask(event.id, {
					status: 0,
					taskPlanningId: parseInt(event.taskPlanningId)
				});

				await setDesiredStartTask(event.id, beginDate);

				await this.getTasksByDate();
			}
		},
		async getTaskList() {
			this.tasks = [];
			const responses = await getTasks(-1, {
				orderBy: true,
				loadSubTasks: false,
				loadItems: false,
				loadTeams: true,
				loadUsers: true,
				loadTreeView: false,
				ofMine: false,
				ofToday: false,
				loadDepartment: this.$route.name === 'AdministrationPlannings' ? store.state.myProfile.department : 0
			});

			let tasksDistinct = [];
			let tmpEvents = [];

			for (let i = 0; i < responses.length; i++) {
				const response = responses[i];
				if (response.putInPlanning) {
					if (response.status === 1) {
						const taskId = parseInt(response.id);

						const desiredStart = new Date(response.desiredStart);
						const end = await this.addDateAndTime(response.desiredStart, 0, response.duration);
						const colorAndTextStatus = await this.getColorAndTextStatus(response.status);
						const result = {
							start: desiredStart,
							end: end,
							title: response.name,
							status: response.status,
							priority: response.priority,
							classStatus: this.getClassColorStatus(response.status),
							backgroundColor: colorAndTextStatus.backgroundColor,
							textColor: colorAndTextStatus.textColor,
							id: taskId,
							taskType: response.type,
							desiredStart: response.desiredStart,
							dayDuration: response.dayDuration,
							timeDuration: response.duration,
							taskPlanningId: response.taskPlanningId,
							taskParentRootName: response.taskParentRootName,

							duration: this.convertTimeToMinute(response.duration),
							durationString: this.convertTimeToString(response.duration, response.dayDuration),
							isCollapsed: false
						};

						const findIndexFromTasksDistinct = tasksDistinct.findIndex((tD) => tD.id === parseInt(response.id));
						if (findIndexFromTasksDistinct === -1) {
							result.taskPlanningsId = [result.taskPlanningId];
							//this.tasks.push(result)
							tasksDistinct.push(result);
						} else {
							tasksDistinct[findIndexFromTasksDistinct].taskPlanningsId.push(result.taskPlanningId);
						}
					} else {
						const result = await this.listTaskToEvent(response);
						if (result !== null) {
							tmpEvents.push(result);
						}
					}
				}
			}
			this.tasks = tasksDistinct;
			this.events = tmpEvents;
			this.originalEvents = tmpEvents;
			await this.getEventsWithFilter();
		},
		async getColorAndTextStatus(statusId) {
			const findIndex = this.statuses.findIndex((cS) => parseInt(cS.value) === parseInt(statusId));

			let backgroundColor = '';
			if (findIndex === -1) {
				backgroundColor = '#000000';
			} else {
				backgroundColor = await this.base16ToHexa(this.statuses[findIndex].factor);
			}

			const textColor = this.invertColor(backgroundColor);

			return {
				backgroundColor,
				textColor
			};
		},
		async getTasksByDate() {
			this.events = [];
			this.preparePlanning = true;
			const taskParentId = this.chckOnlyParent ? 0 : -1;
			const responses = await getTasks(taskParentId, {
				startDate: this.filter.startDate,
				endDate: this.filter.endDate,
				orderBy: true,
				loadSubTasks: false,
				loadItems: false,
				loadTeams: true,
				loadUsers: true,
				loadTreeView: false,
				loadDepartment: this.$route.name === 'AdministrationPlannings' || this.$route.name === 'myPlannings' ? store.state.myProfile.department : 0
			});

			let tmpEvents = [];

			for (let i = 0; i < responses.length; i++) {
				const response = responses[i];
				const taskId = parseInt(response.id);

				const result = await this.listTaskToEvent(response);

				if (result !== null) {
					tmpEvents.push(result);
				}
			}

			this.events = tmpEvents;
			this.originalEvents = tmpEvents;
			await this.getEventsWithFilter();
			this.preparePlanning = false;
		},
		async listTaskToEvent(list) {
			const desiredStart = new Date(list.desiredStart);

			if (list.status !== 1 && list.putInPlanning) {
				const end = this.addDateAndTime(list.desiredStart, 0, list.duration);
				const colorAndTextStatus = await this.getColorAndTextStatus(list.status);
				return {
					start: moment(desiredStart).format('YYYY-MM-DD HH:mm'),
					end: end.format('YYYY-MM-DD HH:mm'),
					title: `<span style="color: ${colorAndTextStatus.textColor}">${list.name}</span>`,
					content: list.type.name,
					status: list.status,
					priority: list.priority,
					taskResourceUsers: list.assignedTo,
					taskResourceTeams: list.assignedTeam,
					class: this.getClassColorStatus(list.status),
					textColor: colorAndTextStatus.textColor,
					id: list.id,
					xid: list.xid,
					clientId: list.clientId,
					client: list.client,
					taskType: list.type,
					originalDuration: list.duration,
					dueDate: list.dueDate,
					description: list.description,
					desiredStart: list.desiredStart,
					dayDuration: list.dayDuration,
					timeDuration: list.duration,
					taskPlanningId: list.taskPlanningId,
					realStart: list.start,
					realEnd: list.end,
					draggable: true,
					taskParentRootName: list.taskParentRootName,
					locationId: list.locationId,
					location: list.location,
					name: list.name
				};
			}
			return null;
		},
		async getEventsWithFilter() {
			if (this.filter.filterActive !== '()') {
				this.events = [];
				for (let i = 0; i < this.originalEvents.length; i++) {
					const event = this.originalEvents[i];
					if (eval(this.filter.filterActive)) {
						this.events.push(event);
					}
				}
			} else {
				this.events = this.originalEvents;
			}
		},
		onEventDelete(payload) {
			this.events = this.events.filter((ev) => ev.id !== payload.id);
			this.tasks.push({
				id: payload.id,
				title: payload.title,
				content: payload.content,
				duration: payload.endTimeMinutes - payload.startTimeMinutes,
				priority: payload.priority,
				status: payload.status,
				isCollapsed: false,
				taskResourceUsers: payload.taskResourceUsers,
				taskResourceTeams: payload.taskResourceTeams,
				clientId: payload.clientId,
				taskType: payload.taskType,
				originalDuration: payload.originalDuration,
				description: payload.description
			});
		},
		/**
		 * Prepare result of taskResourceUsers
		 * @param taskId
		 * @returns {Promise<*[]>}
		 */
		async prepareTaskResourceUsers(taskId, taskPlanningId) {
			const taskResourceUsers = await getTaskResourceUsers(taskPlanningId);
			let res = [];
			if (taskResourceUsers.length > 0) {
				for (const user of this.rawUsers) {
					for (const taskUser of taskResourceUsers) {
						if (user.id === taskUser.id) {
							res.push(user);
						}
					}
				}
			}
			return res;
		},
		async prepareTaskResourceTeams(taskId, taskPlanningId) {
			let taskResourceTeams = await getTaskResourceTeams(taskPlanningId);

			if (taskResourceTeams.length > 0) {
				taskResourceTeams.map((taskResourceTeam) => {
					taskResourceTeam.members.map((member) => {
						for (const user of this.rawUsers) {
							if (member.id === user.id) {
								member.fullName = user.firstName + ' ' + user.name;
								member.dapartment = user.department;
								member.departmentName = user.departmentName;
								member.function = user.function;
								member.functionName = user.functionName;
							}
						}
					});
				});
			}
			return taskResourceTeams;
		},
		customEventsCount(events) {
			return events ? events.length : 0;
		},
		handleToggleTasks() {
			this.toggleTasks = !this.toggleTasks;
			this.setCookiesPlannings('handleToggleTasks');
		},
		getColorOrStrPriority(priority, type = 'color') {
			switch (priority) {
				case 0:
					return type === 'color' ? TaskColorPriority.LOW : this.menuPriority[0].text;
				case 1:
					return type === 'color' ? TaskColorPriority.NORMAL : this.menuPriority[1].text;
				case 2:
					return type === 'color' ? TaskColorPriority.HIGH : this.menuPriority[2].text;
				case 3:
					return type === 'color' ? TaskColorPriority.URGENT : this.menuPriority[3].text;
			}
		},
		getClassColorStatus(status, type = 'class') {
			return type === 'class' ? `statusPlanning${this.statuses[status].value}` : this.statuses[status].text;
		},
		showPicture(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		}
	}
};
</script>

<style>
.vuecal--year-view {
	height: 30%;
}
.vuecal__menu,
.vuecal__cell-events-count {
	background-color: #008b8b;
}
.vuecal__title-bar {
	background-color: #e4f5ef;
}
.vuecal__cell--today,
.vuecal__cell--current {
	background-color: rgba(240, 240, 255, 0.4);
}
.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
	background-color: rgba(235, 255, 245, 0.4);
}
.vuecal__cell--selected:before {
	border-color: rgba(66, 185, 131, 0.5);
}
/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
	background-color: rgba(195, 255, 225, 0.5);
}
.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
	background-color: rgba(136, 236, 191, 0.25);
}

.vuecal--month-view .vuecal__cell {
	height: 80px;
}

.vuecal--month-view .vuecal__cell-content {
	justify-content: flex-start;
	height: 100%;
	align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {
	padding: 4px;
}
.vuecal--month-view .vuecal__no-event {
	display: none;
}
.vuecal__event--dragging {
	background-color: rgba(60, 60, 60, 0.3);
}
.external-event {
	margin-bottom: 0.5em;
	width: 100%;
}
.external-event,
.external-events-drag-and-drop .vuecal__event {
	background-color: rgba(160, 220, 255, 0.5);
	border: 1px solid rgba(0, 100, 150, 0.15);
	padding: 0.2em 0.4em;
	cursor: grab;
}
.vuecal__time-cell-line.hours:before {
	border-color: #42b983;
}
.bc-head-txt-label {
	display: block;
	text-align: left;
	transform: rotate(-90deg);
	margin-top: 19px;
	font-weight: bolder;
	color: white;
	letter-spacing: 2px;
}
.container-hide-show-tasks {
	position: absolute;
	left: 0;
	top: 47%;
	background-color: lightgray;
	height: 60px;
	border-radius: 5px 0 0 5px;
}
.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}

.btn-link.collapse-btn {
	color: #323635;
	text-align: left;
	padding-left: 0;
	padding-right: 0;
	clear: both;
	transition: all 0.4s;
}

.btn-link.collapse-btn:hover,
.btn-link.collapse-btn:active,
.btn-link.collapse-btn:focus {
	padding-left: 8px;
	background-color: #f3f4f5;
	text-decoration: none;
}

.btn-link.collapse-btn i {
	float: right;
	margin: 6px 12px 0 0;
	font-size: 0.7rem;
}
.scroll-area {
	position: relative;
	margin: auto;
	width: 400px;
	height: 300px;
}
</style>
