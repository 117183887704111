<template>
	<div class="mt-1" :class="customClass">
		<span v-if="taskType.picture" :style="{ color: textColor }">
			<b-img :src="pathPicture(taskType.picture)" :alt="taskType.picture" rounded="circle" width="20" height="20" class="m1" />
			<span style="margin-left: 10px; font-style: italic; font-weight: bolder" :style="{ color: textColor }">
				{{ taskType.name }}
			</span>
		</span>
		<span v-else-if="!taskType.picture && taskType.name">
			<b-img
				v-bind="{
					blank: true,
					blankColor: '#777',
					width: 30,
					height: 30,
					class: 'm1'
				}"
				rounded="circle"
				alt="Circle image"
			/>
			<span style="margin-left: 10px" :style="{ color: textColor }">
				{{ taskType.name }}
			</span>
		</span>
	</div>
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'TaskTypeDisplay',

	mixins: [GlobalMixin],

	props: {
		taskType: { type: Object, default: () => {}, required: true },
		textColor: { type: String, default: 'black' },
		customClass: { type: String, default: 'text-center' }
	}
};
</script>

<style scoped></style>
