<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<b-card class="mt-4" no-body>
				<b-card-body>
					<plannings :is-my-plannings="isMyPlannings" />
				</b-card-body>
			</b-card>
		</transition>
	</errorContainer>
</template>

<script>
import PlanningsComponent from '@/components/Plannings/PlanningsComponent';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'Index',

	components: {
		plannings: PlanningsComponent
	},

	mixins: [languageMessages],

	data() {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			isMyPlannings: false
		};
	},

	mounted() {
		if (this.$route.name === 'myPlannings') {
			this.isMyPlannings = true;
		}
	}
};
</script>

<style scoped></style>
