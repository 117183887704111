var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-1", class: _vm.customClass }, [
    _vm.taskType.picture
      ? _c(
          "span",
          { style: { color: _vm.textColor } },
          [
            _c("b-img", {
              staticClass: "m1",
              attrs: {
                src: _vm.pathPicture(_vm.taskType.picture),
                alt: _vm.taskType.picture,
                rounded: "circle",
                width: "20",
                height: "20",
              },
            }),
            _c(
              "span",
              {
                staticStyle: {
                  "margin-left": "10px",
                  "font-style": "italic",
                  "font-weight": "bolder",
                },
                style: { color: _vm.textColor },
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.taskType.name) + "\n\t\t")]
            ),
          ],
          1
        )
      : !_vm.taskType.picture && _vm.taskType.name
      ? _c(
          "span",
          [
            _c(
              "b-img",
              _vm._b(
                { attrs: { rounded: "circle", alt: "Circle image" } },
                "b-img",
                {
                  blank: true,
                  blankColor: "#777",
                  width: 30,
                  height: 30,
                  class: "m1",
                },
                false
              )
            ),
            _c(
              "span",
              {
                staticStyle: { "margin-left": "10px" },
                style: { color: _vm.textColor },
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.taskType.name) + "\n\t\t")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }