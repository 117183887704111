<template>
	<span>
		<b-button variant="primary" @click="$bvModal.show('modal-display-settings')" block>
			<i class="fa fa-cogs" aria-hidden="true" />
			{{ FormMSG(1, 'Settings') }}
		</b-button>

		<b-modal header-class="header-class-modal-doc-package" id="modal-display-settings" hide-footer>
			<template #modal-title> <i class="fa fa-cogs" aria-hidden="true" /> {{ FormMSG(1, 'Settings') }} </template>
			<b-row>
				<b-col cols="6">
					<b-form-group :label="FormMSG(2, 'Time from')" label-for="time-from">
						<b-form-spinbutton id="time-from" v-model="dataToEdit.timeFrom" wrap min="0" max="23" placeholder="0" @input="handleInputTimeFrom" />
					</b-form-group>
				</b-col>
				<b-col cols="6">
					<b-form-group :label="FormMSG(3, 'Time to')" label-for="time-to">
						<b-form-spinbutton id="time-to" v-model="dataToEdit.timeTo" wrap min="0" max="23" placeholder="0" @input="handleInputTimeTo" />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(4, 'Time step')" label-for="time-step">
						<b-form-spinbutton
							id="time-step"
							v-model="dataToEdit.timeStep"
							wrap
							min="10"
							max="30"
							step="10"
							placeholder="0"
							@input="handleInputTimeStep"
						/>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(5, 'Height cellule')" label-for="height-cell" label-cols-sm="4" label-cols-lg="12">
						<b-input-group>
							<b-form-input
								id="height-cell"
								v-model="dataToEdit.heightCell"
								type="range"
								number
								min="10"
								max="50"
								step="10"
								@input="handleInputHeightCell"
							/>
							<b-input-group-append is-text class="text-monospace"> {{ dataToEdit.heightCell }} px </b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-checkbox
						id="chk-show-weeks"
						v-model="dataToEdit.showWeeks"
						name="checkbox-1"
						class="float-right"
						value="false"
						unchecked-value="true"
					>
						{{ FormMSG(57, 'Hide week ends') }}
					</b-form-checkbox>
				</b-col>
			</b-row>
			<b-row class="mt-4">
				<b-col>
					<div class="float-right">
						<b-button variant="primary" class="mr-2" :disabled="formBusy" @click="close">
							{{ FormMSG(7, 'Close') }}
						</b-button>
						<b-button variant="success" :disabled="formBusy" @click="save">
							<span v-if="!formBusy">{{ FormMSG(8, 'Save') }}</span>
							<span v-else>{{ FormMSG(9, 'In progress') }}</span>
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</span>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'DisplaySettingsPlannings',

	mixins: [languageMessages],

	data() {
		return {
			formBusy: false,
			dataToEdit: {
				timeFrom: 0,
				timeTo: 23,
				timeStep: 0,
				showWeeks: true,
				heightCell: 10
			}
		};
	},

	mounted() {
		this.getCookiesConfigPlannings();
	},

	methods: {
		handleInputHeightCell(payload) {
			this.$emit('settings-plannings:input-height-cell', payload);
		},
		handleInputTimeStep(payload) {
			this.$emit('settings-plannings:input-time-step', payload);
		},
		handleInputTimeTo(payload) {
			this.$emit('settings-plannings:input-time-to', payload);
		},
		handleInputTimeFrom(payload) {
			this.$emit('settings-plannings:input-time-from', payload);
		},
		getCookiesConfigPlannings() {
			if (this.$cookies.isKey('configPlannings')) {
				const cookies = this.$cookies.get('configPlannings');

				this.dataToEdit.timeFrom = cookies.timeFrom;
				this.dataToEdit.timeTo = cookies.timeTo;
				this.dataToEdit.timeStep = cookies.timeStep === 0 ? 30 : cookies.timeStep;
				this.dataToEdit.showWeeks = cookies.showWeeks;
				this.dataToEdit.heightCell = cookies.heightCell;
			}
		},
		save() {
			this.formBusy = true;
			this.emitEvent();
			this.formBusy = false;
			this.$bvModal.hide('modal-display-settings');
		},
		close() {
			this.getCookiesConfigPlannings();
			this.$emit('settings-plannings:close-modal');
			this.$bvModal.hide('modal-display-settings');
		},

		emitEvent() {
			const dataToEmit = {
				timeFrom: this.dataToEdit.timeFrom,
				timeTo: this.dataToEdit.timeTo,
				timeStep: this.dataToEdit.timeStep,
				showWeeks: this.dataToEdit.showWeeks,
				heightCell: this.dataToEdit.heightCell
			};

			this.$cookies.set('configPlannings', JSON.stringify(dataToEmit));

			this.$emit('settings-plannings:change', dataToEmit);
		}
	}
};
</script>

<style scoped></style>
