var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.users.length === 0 && _vm.teams.length === 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.bottom.html",
                  value: _vm.FormMSG(41, "No assignement"),
                  expression: "FormMSG(41, 'No assignement')",
                  modifiers: { hover: true, bottom: true, html: true },
                },
              ],
              staticClass: "not-img",
              staticStyle: { "background-color": "red" },
            },
            [_c("span", [_vm._v("?")])]
          )
        : _vm._e(),
      _vm._l(_vm.users, function (user, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "lazy-container",
                rawName: "v-lazy-container",
                value: { selector: "img" },
                expression: "{ selector: 'img' }",
              },
            ],
            key: index,
            staticClass: "transition-img-circle-custom",
            staticStyle: { display: "inline-block", position: "relative" },
            style: {
              marginLeft: index > 0 ? "-12px" : "0px",
              zIndex: _vm.listUsers.length + 500 - index,
            },
            attrs: { id: `${user.identifier}` },
            on: {
              mouseenter: function ($event) {
                return _vm.decalImageOnHover("" + user.identifier, index)
              },
              mouseleave: function ($event) {
                return _vm.resetDecalImage("" + user.identifier, index)
              },
            },
          },
          [
            user.picture !== ""
              ? _c("img", {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.bottom.html",
                      value: `${user.name} ${user.firstName}`,
                      expression: "`${user.name} ${user.firstName}`",
                      modifiers: { hover: true, bottom: true, html: true },
                    },
                  ],
                  staticClass: "img-circle-custom",
                  attrs: {
                    "data-src": _vm.pathPicture(user.picture),
                    "data-error": "img/brand/avatar.jpg",
                    "data-loading": "img/brand/tenor.gif",
                    alt: user.picture,
                  },
                })
              : _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.bottom.html",
                        value: `${user.name} ${user.firstName}`,
                        expression: "`${user.name} ${user.firstName}`",
                        modifiers: { hover: true, bottom: true, html: true },
                      },
                    ],
                    staticClass: "not-img",
                    style: {
                      backgroundColor: user.color,
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.getInitial(user.name)) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
          ]
        )
      }),
      _vm._l(_vm.teams, function (team, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "transition-img-circle-custom",
            staticStyle: { display: "inline-block", position: "relative" },
            style: {
              marginLeft: "-12px",
              zIndex: _vm.teams.length + _vm.users.length - index,
            },
            attrs: { id: `${team.identifier}` },
            on: {
              mouseenter: function ($event) {
                return _vm.decalImageOnHover(
                  "" + team.identifier,
                  index,
                  "team"
                )
              },
              mouseleave: function ($event) {
                return _vm.resetDecalImage("" + team.identifier, index, "team")
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.bottom.html",
                    value: `
          <div class='row'>
            <div class='col'>
              ${team.name}
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <span style='font-weight: bolder'>${_vm.FormMSG(
                1,
                "Members"
              )} :</span>
              <ul style='list-style: none; padding-left: 5px'>
               ${team.membersString}
              </ul>
            </div>
          </div>
        `,
                    expression:
                      "\n\t\t\t\t\t`\n          <div class='row'>\n            <div class='col'>\n              ${team.name}\n            </div>\n          </div>\n          <div class='row'>\n            <div class='col'>\n              <span style='font-weight: bolder'>${FormMSG(1, 'Members')} :</span>\n              <ul style='list-style: none; padding-left: 5px'>\n               ${team.membersString}\n              </ul>\n            </div>\n          </div>\n        `\n\t\t\t\t",
                    modifiers: { hover: true, bottom: true, html: true },
                  },
                ],
                staticClass: "not-img",
                style: {
                  backgroundColor: `#${
                    team.color > 0
                      ? new Number(team.color).toString(16).padStart(6, "0")
                      : "32C1CD"
                  }`,
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.getInitial(team.name)) + "\n\t\t\t"
                ),
              ]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }