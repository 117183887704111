var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        id: "modal-planning",
        "hide-footer": "",
        size: _vm.$screen.width <= 992 ? "md" : "lg",
      },
      on: { hidden: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              !_vm.isMyPlannings
                ? _c(
                    "span",
                    { staticClass: "text-primary" },
                    [
                      _c("i", {
                        staticClass: "fa fa-arrow-right",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" \n\t\t\t"),
                      _c("router-link", {
                        staticClass: "text-primary",
                        attrs: { to: _vm.toPath },
                        domProps: {
                          innerHTML: _vm._s(_vm.selectedEvent.title),
                        },
                      }),
                    ],
                    1
                  )
                : _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.selectedEvent.title) },
                  }),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            },
          },
        },
        [
          !_vm.commentMode
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("change-status-planning", {
                            attrs: { "status-id": _vm.dataToEdit.status },
                            on: { "status:selected": _vm.selectStatus },
                          }),
                        ],
                        1
                      ),
                      _vm.isMyPlannings
                        ? _c("b-col", { attrs: { cols: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "float-right" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      variant: "success",
                                      disabled: _vm.disabledBtnStart,
                                    },
                                    on: { click: _vm.handleClickRealStart },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-play",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(55, "Start")) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "danger",
                                      disabled: _vm.disabledBtnFinish,
                                    },
                                    on: { click: _vm.handleClickRealEnd },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-stop-circle",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(56, "Finish")) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isMyPlannings
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("div", { staticClass: "float-right" }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(57, "Real start")) +
                                    " : " +
                                    _vm._s(
                                      _vm.formatDisplayDate(
                                        _vm.dataToEdit.realStart
                                      )
                                    )
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(58, "Real end")) +
                                    " : " +
                                    _vm._s(
                                      _vm.formatDisplayDate(
                                        _vm.dataToEdit.realEnd
                                      )
                                    )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedEvent.taskType &&
                  _vm.selectedEvent.taskType.name !== ""
                    ? _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("task-type-display", {
                                attrs: {
                                  "task-type": _vm.selectedEvent.taskType,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedEvent.client && _vm.selectedEvent.client.name
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(165, "Client") },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      value: _vm.selectedEvent.client.name,
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedEvent.clientId !== 0
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(166, "Address") },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("b-form-input", {
                                        staticStyle: { width: "95%" },
                                        attrs: {
                                          value: _vm.addressFormatter,
                                          disabled: "",
                                        },
                                      }),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "float-right",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleClickLocalisation(
                                                _vm.selectedEvent
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon-location-pin",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedEvent.client && _vm.selectedEvent.client.phone
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(168, "Phone") } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.selectedEvent.client.phone,
                                          disabled: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(169, "Email") } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value:
                                            _vm.selectedEvent.client &&
                                            _vm.selectedEvent.client.email
                                              ? _vm.selectedEvent.client.email
                                              : "",
                                          disabled: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-3 mb-4" },
                    [
                      _c("b-col", [
                        _c("div", {
                          staticClass: "modal-planning-description",
                          class: {
                            "height-150": !_vm.seeMore.showMore,
                            "height-auto": _vm.seeMore.showMore,
                          },
                          domProps: {
                            innerHTML: _vm._s(_vm.selectedEvent.description),
                          },
                        }),
                        _vm.seeMore.visible
                          ? _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn-modal-planning-see-more",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.seeMore.showMore =
                                        !_vm.seeMore.showMore
                                    },
                                  },
                                },
                                [
                                  !_vm.seeMore.showMore
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "fa fa-chevron-down",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(23, "Show more")
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.seeMore.showMore
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "fa fa-chevron-up",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(24, "Show less")
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-2" },
                    [
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.FormMSG(2, "Event starts at"))),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              locale: _vm.lang,
                              disabled: _vm.isMyPlannings,
                            },
                            model: {
                              value: _vm.dataToEdit.desiredStart,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "desiredStart", $$v)
                              },
                              expression: "dataToEdit.desiredStart",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("b-form-timepicker", {
                            attrs: {
                              required: "",
                              placeholder: _vm.FormMSG(40, "Select time"),
                              disabled: _vm.isMyPlannings,
                            },
                            on: { input: _vm.handleInputDataToEditStart },
                            model: {
                              value: _vm.dataToEdit.start,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "start", $$v)
                              },
                              expression: "dataToEdit.start",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.FormMSG(3, "Event ends at"))),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              locale: _vm.lang,
                              disabled: _vm.isMyPlannings,
                            },
                            model: {
                              value: _vm.dataToEdit.desiredStart,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "desiredStart", $$v)
                              },
                              expression: "dataToEdit.desiredStart",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("b-form-timepicker", {
                            attrs: {
                              required: "",
                              placeholder: _vm.FormMSG(40, "Select time"),
                              disabled: _vm.isMyPlannings,
                            },
                            on: { input: _vm.handleInputDataToEditEnd },
                            model: {
                              value: _vm.dataToEdit.end,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "end", $$v)
                              },
                              expression: "dataToEdit.end",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isMyPlannings
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-card",
                                {
                                  attrs: {
                                    "no-body": "",
                                    "header-tag": "header",
                                    "footer-tag": "footer",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function () {
                                          return [
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "mb-0 font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      41,
                                                      "Assigned users"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.canManagePlanning
                                        ? {
                                            key: "footer",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "tags-assigned",
                                                          },
                                                          [
                                                            _c(
                                                              "task-user-selector",
                                                              {
                                                                attrs: {
                                                                  "task-planning-id":
                                                                    _vm
                                                                      .selectedEvent
                                                                      .taskPlanningId,
                                                                  "assigned-to":
                                                                    _vm
                                                                      .dataToEdit
                                                                      .taskResourceUsers,
                                                                },
                                                                on: {
                                                                  "task-user-selector:userAdded":
                                                                    _vm.handleUserAdded,
                                                                  "task-user-selector:userDeleted":
                                                                    _vm.handleUserDeleted,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "b-card-body",
                                    [
                                      _c("task-assigned-user", {
                                        attrs: {
                                          col: "6",
                                          items:
                                            _vm.dataToEdit.taskResourceUsers,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isMyPlannings
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-card",
                                {
                                  attrs: {
                                    "no-body": "",
                                    "header-tag": "header",
                                    "footer-tag": "footer",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function () {
                                          return [
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "mb-0 font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        30,
                                                        "Assigned teams"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.canManagePlanning
                                        ? {
                                            key: "footer",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c("b-col", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tags-assigned",
                                                        },
                                                        [
                                                          _c(
                                                            "task-team-selector",
                                                            {
                                                              attrs: {
                                                                "task-planning-id":
                                                                  _vm
                                                                    .selectedEvent
                                                                    .taskPlanningId,
                                                                "assigned-team":
                                                                  _vm.dataToEdit
                                                                    .taskResourceTeams,
                                                              },
                                                              on: {
                                                                "task-team-selector:teamAdded":
                                                                  _vm.handleTeamAdded,
                                                                "task-team-selector:teamDeleted":
                                                                  _vm.handleTeamDeleted,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "b-card-body",
                                    [
                                      _c("task-assigned-team", {
                                        attrs: {
                                          col: "6",
                                          items:
                                            _vm.dataToEdit.taskResourceTeams,
                                          "can-do-edit":
                                            !_vm.isMyPlannings &&
                                            _vm.canManagePlanning,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: {
                                click: function ($event) {
                                  _vm.commentMode = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(45, "Comments")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.commentMode
            ? _c(
                "div",
                [
                  _c("comments", {
                    attrs: {
                      "key-value": {
                        xid:
                          _vm.selectedEvent.rootXid === ""
                            ? _vm.selectedEvent.xid
                            : _vm.selectedEvent.rootXid,
                        imageParentId:
                          parseInt(_vm.selectedEvent.rootId) === 0
                            ? parseInt(_vm.selectedEvent.id)
                            : parseInt(_vm.selectedEvent.rootId),
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _vm.commentMode
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: { variant: "success" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.commentMode = false
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { icon: "arrow-left-square" },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.FormMSG(25, "Back")) +
                                "\n\t\t\t\t\t"
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { disabled: _vm.formBusy },
                        on: { click: _vm.hideModal },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(10, "Close")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    !_vm.commentMode
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "success",
                              type: "submit",
                              disabled: _vm.formBusy,
                            },
                          },
                          [
                            _vm.formBusy
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        43,
                                        "Modification in progress"
                                      )
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.FormMSG(42, "Save"))),
                                ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }