var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("custom-loading-planning", {
        attrs: {
          loading: _vm.preparePlanning,
          label: _vm.FormMSG(
            103,
            "Preparation of the planning and the list of tasks"
          ),
        },
      }),
      _c("b-col", { attrs: { sm: "12" } }, [
        _c("h1", { class: `${!_vm.$screen.sm ? "main-page-title" : ""}` }, [
          _vm._v(
            _vm._s(
              !_vm.toMe
                ? _vm.FormMSG(159, "Plannings")
                : _vm.FormMSG(16, "My Plannings")
            )
          ),
        ]),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          !_vm.toMe
            ? _c("b-col", { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } }, [
                _c(
                  "fieldset",
                  { staticClass: "scheduler-border my-0 py-0 pb-3" },
                  [
                    _c("legend", { staticClass: "scheduler-border" }, [
                      _vm._v(_vm._s(_vm.FormMSG(14, "Menus"))),
                    ]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            class: `${
                              _vm.$screen.lg ||
                              _vm.$screen.md ||
                              _vm.$screen.width < 768
                                ? "mb-2"
                                : ""
                            }`,
                            attrs: { sm: "12", md: "12", lg: "12", xl: "6" },
                          },
                          [
                            _c("display-settings-plannings", {
                              staticClass: "w-100",
                              on: {
                                "settings-plannings:change":
                                  _vm.handleSettingsPlanningsChange,
                                "settings-plannings:input-height-cell":
                                  _vm.handleSettingsPlanningsInputHeightCell,
                                "settings-plannings:input-time-step":
                                  _vm.handleSettingsPlanningsInputTimeStep,
                                "settings-plannings:input-time-to":
                                  _vm.handleSettingsPlanningsInputTimeTo,
                                "settings-plannings:input-time-from":
                                  _vm.handleSettingsPlanningsInputTimeFrom,
                                "settings-plannings:close-modal":
                                  _vm.handleSettingsPlanningsCloseModal,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            class: `${
                              _vm.$screen.lg ||
                              _vm.$screen.md ||
                              _vm.$screen.width < 768
                                ? "mb-2"
                                : ""
                            }`,
                            attrs: { sm: "12", md: "12", lg: "12", xl: "6" },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-success w-100",
                                attrs: { to: "/gantt", tag: "button" },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-tasks",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t " +
                                    _vm._s(_vm.FormMSG(125, "Gantt")) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "12", xl: "6" } },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-success w-100",
                                attrs: { to: "/perAssignee", tag: "button" },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-user-o",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t " +
                                    _vm._s(_vm.FormMSG(169, "Per assignee")) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "12", xl: "6" } },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-success w-100",
                                attrs: {
                                  to: "/assignationMultiple",
                                  tag: "button",
                                  disabled: !_vm.canManagePlanning,
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-users",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t " +
                                    _vm._s(
                                      _vm.FormMSG(45, "Multiple assignments")
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("b-col", { attrs: { sm: "12", md: "12", lg: "8", xl: "8" } }, [
            _c(
              "fieldset",
              { staticClass: "scheduler-border my-0 py-0" },
              [
                _c("legend", { staticClass: "scheduler-border" }, [
                  _vm._v(_vm._s(_vm.FormMSG(15, "Advanced search"))),
                ]),
                _c("filter-planning", {
                  ref: "filter-planning",
                  attrs: {
                    "field-filter": "event",
                    "to-me": _vm.toMe,
                    "only-parent": _vm.chckOnlyParent,
                    "init-active-view": "week",
                  },
                  on: {
                    "filters:selected": _vm.handleFiltersSelected,
                    "new-active-view": _vm.handleNewActiveView,
                    "today-event": function ($event) {
                      _vm.$refs.taskcal.switchView("day", new Date())
                    },
                    "specific-date:selected": _vm.handleSpecificDateSelected,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "pb-2", attrs: { sm: "12" } }, [
            _c(
              "div",
              { staticClass: "pull-right" },
              [
                !_vm.toMe
                  ? _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: { click: _vm.handleToggleTasks },
                      },
                      [
                        !_vm.toggleTasks
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-angle-double-right",
                                staticStyle: { "margin-left": "2px" },
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t " +
                                  _vm._s(_vm.FormMSG(43, "Hide")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ])
                          : _c("span", [
                              _c("i", {
                                staticClass: "fa fa-angle-double-left",
                                staticStyle: { "margin-left": "2px" },
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t " +
                                  _vm._s(_vm.FormMSG(44, "Show")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticStyle: { transition: "max-width 0.5s" },
              attrs: { cols: !_vm.toggleTasks ? 9 : 12 },
            },
            [
              _c("vue-cal", {
                ref: "taskcal",
                staticStyle: { height: "auto" },
                attrs: {
                  "selected-date": _vm.selectedDate,
                  "active-view": _vm.activeView,
                  "disable-views": _vm.disableViews,
                  events: _vm.events,
                  locale: _vm.lang,
                  "on-event-click": _vm.openDetailsForPlanning,
                  "time-step": _vm.configPlannings.timeStep,
                  "time-from": _vm.configPlannings.timeFrom * 60,
                  "time-to": _vm.configPlannings.timeTo * 60,
                  "time-cell-height": _vm.configPlannings.heightCell,
                  "hide-weekends": !_vm.configPlannings.showWeeks,
                  "resize-x": true,
                  "events-on-month-view": "short",
                  "events-count-on-year-view": "",
                  "hide-view-selector": "",
                  "drag-to-create-event": false,
                  "cell-click-hold": false,
                  "editable-events": _vm.checkEditableEvents,
                },
                on: {
                  "update:activeView": function ($event) {
                    _vm.activeView = $event
                  },
                  "update:active-view": function ($event) {
                    _vm.activeView = $event
                  },
                  "event-drop": _vm.onEventDrop,
                  "event-delete": _vm.onEventDelete,
                  "event-duration-change": _vm.handleEventDurationChange,
                  "event-change": _vm.handleEventChange,
                  "view-change": _vm.handleViewChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "event",
                    fn: function ({ event, view }) {
                      return [
                        !["years", "year", "month"].includes(view)
                          ? _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-left ml-3",
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c("view-list-img-circle", {
                                      attrs: {
                                        "list-users": event.taskResourceUsers,
                                        "list-teams": event.taskResourceTeams,
                                      },
                                    }),
                                    _c("i", {
                                      staticClass: "fa fa-flag",
                                      staticStyle: {
                                        position: "absolute",
                                        right: "10px",
                                        top: "2px",
                                      },
                                      style: {
                                        color: _vm.getColorOrStrPriority(
                                          event.priority
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", {
                                  staticClass:
                                    "vuecal__event-title vuecal__event-title--edit mt-3",
                                  staticStyle: { "font-weight": "bolder" },
                                  style: {
                                    color: `${event.textColor} !important`,
                                  },
                                  attrs: { contenteditable: "" },
                                  domProps: { innerHTML: _vm._s(event.title) },
                                  on: {
                                    blur: function ($event) {
                                      event.title = $event.target.innerHTML
                                    },
                                  },
                                }),
                                _c("task-type-display", {
                                  attrs: {
                                    "task-type": event.taskType,
                                    "text-color": event.textColor,
                                  },
                                }),
                                event.clientId !== 0
                                  ? _c(
                                      "div",
                                      { staticClass: "text-center mt-1" },
                                      [
                                        event.taskParentRootName !== "0"
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-3 mb-3" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-weight": "bolder",
                                                      "font-style": "italic",
                                                    },
                                                    style: {
                                                      color: `${event.textColor} !important`,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            170,
                                                            "Main task"
                                                          )
                                                        ) +
                                                        " :\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      color: `${event.textColor} !important`,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          event.taskParentRootName
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bolder",
                                                "font-style": "italic",
                                              },
                                              style: {
                                                color: `${event.textColor} !important`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(165, "Client")
                                                  ) +
                                                  " :\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              style: {
                                                color: `${event.textColor} !important`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(event.client.name) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bolder",
                                                "font-style": "italic",
                                              },
                                              style: {
                                                color: `${event.textColor} !important`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(166, "Address")
                                                  ) +
                                                  " :\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          event.locationId === 0
                                            ? _c(
                                                "span",
                                                {
                                                  style: {
                                                    color: `${event.textColor} !important`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.getAddressFormater(
                                                          event.client.address
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  style: {
                                                    color: `${event.textColor} !important`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.getAddressFormater(
                                                          event.location
                                                            .setAddress
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticStyle: {
                                                  background: "none",
                                                  border: "none",
                                                  "font-size": "16px",
                                                  color: "#43c3d0",
                                                },
                                                style: {
                                                  color: `${event.textColor} !important`,
                                                },
                                                attrs: { variant: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleClickLocalisation(
                                                      event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon-location-pin",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "time-cell",
                    fn: function ({ hours, minutes }) {
                      return [
                        _c(
                          "div",
                          {
                            class: {
                              "vuecal__time-cell-line": true,
                              hours: !minutes,
                            },
                          },
                          [
                            !minutes
                              ? _c(
                                  "strong",
                                  { staticStyle: { "font-size": "15px" } },
                                  [_vm._v(_vm._s(hours))]
                                )
                              : _c(
                                  "span",
                                  { staticStyle: { "font-size": "11px" } },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(minutes) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "events-count",
                    fn: function ({ events, view }) {
                      return [
                        _vm.customEventsCount(events)
                          ? _c("span", [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.customEventsCount(events)) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("modal-planning", {
                attrs: {
                  open: _vm.showDialog,
                  "selected-event": _vm.selectedEvent,
                  "raw-users": _vm.rawUsers,
                  "raw-teams": _vm.rawTeams,
                },
                on: {
                  "modal:close": _vm.closeDetailsForPlanning,
                  "change:info": _vm.handleChangeInfoEvent,
                  "change:info-task": _vm.handleChangeInfoTask,
                  "change:push-task-to-event": _vm.handleChangePushTaskToEvent,
                  "refresh-plannings": _vm.handleRefreshPlannings,
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticStyle: { "z-index": "0" },
              style: { display: !_vm.toggleTasks ? "block" : "none" },
              attrs: { cols: !_vm.toggleTasks ? 3 : "" },
            },
            [
              _c(
                "div",
                { staticClass: "sidenav-sticky sticky-top pb-4" },
                [
                  _c("h3", [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.FormMSG(61, "Tasks"))),
                    ]),
                  ]),
                  _vm.tasks.length === 0
                    ? _c("div", { staticClass: "mt-5" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(46, "No tasks to plan")) +
                            "\n\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "b-list-group",
                    {
                      staticStyle: { "overflow-y": "scroll" },
                      style: { height: _vm.heightRightTask },
                      attrs: { id: "task-planning" },
                    },
                    [
                      _vm._l(_vm.tasks, function (item, index) {
                        return _c(
                          "b-list-group-item",
                          {
                            key: index,
                            staticClass: "flex-column align-items-start",
                            attrs: { href: "#", draggable: true },
                            on: {
                              dragstart: function ($event) {
                                return _vm.onEventDragStart($event, item)
                              },
                              click: function ($event) {
                                return _vm.openDetails(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex w-100 justify-content-between",
                              },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { "font-weight": "bolder" } },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                                _c("small", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-2",
                                      staticStyle: {
                                        display: "inline-block",
                                        "border-radius": "5px",
                                        "text-transform": "capitalize",
                                        padding: "3px",
                                        color: "white",
                                        "font-weight": "bolder",
                                      },
                                      style: {
                                        backgroundColor: `#${item.backgroundColor}`,
                                        color: `#${item.textColor}`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.getClassColorStatus(
                                              item.status,
                                              "text"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.bottom",
                                        value: _vm.getColorOrStrPriority(
                                          item.priority,
                                          "str"
                                        ),
                                        expression:
                                          "getColorOrStrPriority(item.priority, 'str')",
                                        modifiers: {
                                          hover: true,
                                          bottom: true,
                                        },
                                      },
                                    ],
                                    staticClass: "fa fa-flag",
                                    staticStyle: { "font-size": "15px" },
                                    style: {
                                      color: _vm.getColorOrStrPriority(
                                        item.priority
                                      ),
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            item.taskParentRootName !== "0"
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-top": "-10px" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "bolder",
                                          "font-style": "italic",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(170, "Main task")
                                          ) + " : "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "font-style": "italic" },
                                      },
                                      [_vm._v(_vm._s(item.taskParentRootName))]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "small",
                              {
                                staticClass: "float-left",
                                staticStyle: {
                                  color: "gray",
                                  "font-weight": "bolder",
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(item.durationString) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "small",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.bottom",
                                    value: _vm.FormMSG(
                                      13,
                                      "View information for this task"
                                    ),
                                    expression:
                                      "FormMSG(13, 'View information for this task')",
                                    modifiers: { hover: true, bottom: true },
                                  },
                                ],
                                staticClass: "float-right",
                                staticStyle: {
                                  color: "gray",
                                  "font-weight": "bolder",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-eye",
                                  staticStyle: { "font-size": "15px" },
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]
                        )
                      }),
                      _c("modal-task-planning", {
                        attrs: {
                          open: _vm.showDialogTasks,
                          "selected-event": _vm.selectedTask,
                        },
                        on: {
                          "modal:close": _vm.closeDetails,
                          "task:save": _vm.handleTaskSave,
                          "task:save-planned": _vm.handleTaskSavePlanned,
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }