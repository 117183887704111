<template>
	<div>
		<div
			v-if="users.length === 0 && teams.length === 0"
			style="background-color: red"
			class="not-img"
			v-b-popover.hover.bottom.html="FormMSG(41, 'No assignement')"
		>
			<span>?</span>
		</div>
		<div
			v-for="(user, index) in users"
			:id="`${user.identifier}`"
			:key="index"
			style="display: inline-block; position: relative"
			:style="{
				marginLeft: index > 0 ? '-12px' : '0px',
				zIndex: listUsers.length + 500 - index
			}"
			class="transition-img-circle-custom"
			v-lazy-container="{ selector: 'img' }"
			@mouseenter="decalImageOnHover('' + user.identifier, index)"
			@mouseleave="resetDecalImage('' + user.identifier, index)"
		>
			<img
				v-if="user.picture !== ''"
				:data-src="pathPicture(user.picture)"
				data-error="img/brand/avatar.jpg"
				data-loading="img/brand/tenor.gif"
				:alt="user.picture"
				class="img-circle-custom"
				v-b-popover.hover.bottom.html="`${user.name} ${user.firstName}`"
			/>
			<div
				v-else
				:style="{
					backgroundColor: user.color
				}"
				class="not-img"
				v-b-popover.hover.bottom.html="`${user.name} ${user.firstName}`"
			>
				{{ getInitial(user.name) }}
			</div>
		</div>
		<div
			v-for="(team, index) in teams"
			:id="`${team.identifier}`"
			:key="index"
			style="display: inline-block; position: relative"
			:style="{
				marginLeft: '-12px',
				zIndex: teams.length + users.length - index
			}"
			class="transition-img-circle-custom"
			@mouseenter="decalImageOnHover('' + team.identifier, index, 'team')"
			@mouseleave="resetDecalImage('' + team.identifier, index, 'team')"
		>
			<div
				:style="{
					backgroundColor: `#${team.color > 0 ? new Number(team.color).toString(16).padStart(6, '0') : '32C1CD'}`
				}"
				class="not-img"
				v-b-popover.hover.bottom.html="
					`
          <div class='row'>
            <div class='col'>
              ${team.name}
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <span style='font-weight: bolder'>${FormMSG(1, 'Members')} :</span>
              <ul style='list-style: none; padding-left: 5px'>
               ${team.membersString}
              </ul>
            </div>
          </div>
        `
				"
			>
				{{ getInitial(team.name) }}
			</div>
		</div>
	</div>
</template>

<script>
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'ViewListImgCircle',

	components: {
		popper: Popper
	},

	mixins: [languageMessages, GlobalMixin],

	props: {
		listUsers: { type: Array, default: () => [], required: true },
		listTeams: { type: Array, default: () => [], required: true }
	},

	computed: {
		users() {
			this.listUsers.map((lU) => {
				lU.identifier = this.uniqueID();
			});
			return this.listUsers;
		},
		teams() {
			this.listTeams.map((lT) => {
				lT.identifier = this.uniqueID();
				let membersString = [];
				lT.members.forEach((member) => {
					membersString.push(`<li>${member.name} ${member.firstName}</li>`);
				});
				lT.membersString = membersString.join('');
			});
			return this.listTeams;
		}
	},

	methods: {
		decalImageOnHover(identifiant, index, type = 'user') {
			this.$nextTick(() => {
				if (index === 0 && type === 'user') {
					return;
				} else {
					const imgCircle = document.getElementById(`${identifiant}`);
					imgCircle.style.marginLeft = '2px';
				}
			});
		},
		resetDecalImage(identifiant, index, type = 'user') {
			this.$nextTick(() => {
				if (index === 0 && type === 'user') {
					return;
				} else {
					const imgCircle = document.getElementById(`${identifiant}`);
					imgCircle.style.marginLeft = '-12px';
				}
			});
		},
		pathPicture(picture) {
			return `${process.env.VUE_APP_GQL}/images/${picture}`;
		},
		randomColor() {
			const items = ['#370665', '#F14A16', '#A2D2FF', '#865439'];
			return items[Math.floor(Math.random() * items.length)];
		},
		uniqueID() {
			return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
		}
	}
};
</script>

<style>
.img-circle-custom {
	border-radius: 50%;
	width: 25px;
	height: 25px;
}
.not-img {
	display: inline-block;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	font-weight: bolder;
	color: white;
	text-align: center;
	padding-top: 3px;
}
.transition-img-circle-custom {
	transition: margin-left 0.5s;
}
.popper {
	border-radius: 5px;
	padding: 0 3px;
	text-align: left !important;
	font-size: 10px !important;
	min-width: 75px !important;
	height: auto !important;
}
</style>
