var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "b-button",
        {
          attrs: { variant: "primary", block: "" },
          on: {
            click: function ($event) {
              return _vm.$bvModal.show("modal-display-settings")
            },
          },
        },
        [
          _c("i", {
            staticClass: "fa fa-cogs",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v("\n\t\t" + _vm._s(_vm.FormMSG(1, "Settings")) + "\n\t"),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "header-class": "header-class-modal-doc-package",
            id: "modal-display-settings",
            "hide-footer": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c("i", {
                    staticClass: "fa fa-cogs",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" " + _vm._s(_vm.FormMSG(1, "Settings")) + " "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(2, "Time from"),
                        "label-for": "time-from",
                      },
                    },
                    [
                      _c("b-form-spinbutton", {
                        attrs: {
                          id: "time-from",
                          wrap: "",
                          min: "0",
                          max: "23",
                          placeholder: "0",
                        },
                        on: { input: _vm.handleInputTimeFrom },
                        model: {
                          value: _vm.dataToEdit.timeFrom,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "timeFrom", $$v)
                          },
                          expression: "dataToEdit.timeFrom",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(3, "Time to"),
                        "label-for": "time-to",
                      },
                    },
                    [
                      _c("b-form-spinbutton", {
                        attrs: {
                          id: "time-to",
                          wrap: "",
                          min: "0",
                          max: "23",
                          placeholder: "0",
                        },
                        on: { input: _vm.handleInputTimeTo },
                        model: {
                          value: _vm.dataToEdit.timeTo,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "timeTo", $$v)
                          },
                          expression: "dataToEdit.timeTo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(4, "Time step"),
                        "label-for": "time-step",
                      },
                    },
                    [
                      _c("b-form-spinbutton", {
                        attrs: {
                          id: "time-step",
                          wrap: "",
                          min: "10",
                          max: "30",
                          step: "10",
                          placeholder: "0",
                        },
                        on: { input: _vm.handleInputTimeStep },
                        model: {
                          value: _vm.dataToEdit.timeStep,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "timeStep", $$v)
                          },
                          expression: "dataToEdit.timeStep",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(5, "Height cellule"),
                        "label-for": "height-cell",
                        "label-cols-sm": "4",
                        "label-cols-lg": "12",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "height-cell",
                              type: "range",
                              number: "",
                              min: "10",
                              max: "50",
                              step: "10",
                            },
                            on: { input: _vm.handleInputHeightCell },
                            model: {
                              value: _vm.dataToEdit.heightCell,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "heightCell", $$v)
                              },
                              expression: "dataToEdit.heightCell",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            {
                              staticClass: "text-monospace",
                              attrs: { "is-text": "" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.dataToEdit.heightCell) + " px "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "float-right",
                      attrs: {
                        id: "chk-show-weeks",
                        name: "checkbox-1",
                        value: "false",
                        "unchecked-value": "true",
                      },
                      model: {
                        value: _vm.dataToEdit.showWeeks,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataToEdit, "showWeeks", $$v)
                        },
                        expression: "dataToEdit.showWeeks",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(57, "Hide week ends")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "primary", disabled: _vm.formBusy },
                        on: { click: _vm.close },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(7, "Close")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "success", disabled: _vm.formBusy },
                        on: { click: _vm.save },
                      },
                      [
                        !_vm.formBusy
                          ? _c("span", [_vm._v(_vm._s(_vm.FormMSG(8, "Save")))])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.FormMSG(9, "In progress"))),
                            ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }